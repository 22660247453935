import { ReactNode } from "react";
import BulletedTextListDescription from "./BullettedTextListDescription";

export interface BulletedTextListProps {
  children: ReactNode;
  header: string;
  description: string | ReactNode;
  bodyClassName?: string;
}

export default function BulletedTextList({
  children,
  header,
  description,
  bodyClassName,
}: BulletedTextListProps) {
  return (
    <div className={`mt-xl-0 col-xl-8 ${bodyClassName ? bodyClassName : ""}`}>
      {header && <h2 className="text-5xl mb-4 mt-3.5">{header}</h2>}
      <div className="my-1">
        <ul className="list-none list-image-none list-inside pl-0">
          {children}
        </ul>
      </div>
      <div className="[&_h2]:text-3xl">
        <BulletedTextListDescription description={description} />
      </div>
    </div>
  );
}
