"use client";
import CTALink from "@/components/CTA/ctaLink";
import BulletedTextList from "@/components/TextLists/BulletedTextList/BulletedTextList";
import BulletedTextListItem from "@/components/TextLists/BulletedTextList/BulletedTextListItem";
import { FeaturedProductsProps } from "./types";
import { ElementType } from "@/constants/tagular/main";
import { validateLocation } from "@/utils/functions/validateLocation";

export default function FeaturedProducts({
  introduction,
  listTitle,
  ctaLabel,
  ctaUrl,
  productList,
  headerClassName,
  bodyClassName,
  ctaHeader,
  location,
  html_id,
  locationRestrictions,
}: FeaturedProductsProps) {
  const isValidLocation = validateLocation(
    locationRestrictions?.allowedIn || [],
    locationRestrictions?.blockedIn || [],
  );
  return (
    <div>
      {typeof introduction === "string" ? (
        <p className={headerClassName}>{introduction}</p>
      ) : (
        introduction
      )}
      {listTitle && <p>{listTitle}</p>}
      <BulletedTextList header="" description="" bodyClassName={bodyClassName}>
        {productList.map((item) => (
          <BulletedTextListItem
            key={item.header}
            header={item.header}
            description={item.description}
            listStyle="none"
          />
        ))}
      </BulletedTextList>
      {isValidLocation && (
        <>
          {ctaHeader && (
            <h2 className="w-full text-center justify-center mb-4">
              {ctaHeader}
            </h2>
          )}
          <div className="flex items-center justify-center">
            <CTALink
              type="brand"
              size="xl"
              label={ctaLabel}
              url={ctaUrl}
              icon="arrow-right"
              element_type={ElementType.Button}
              html_id={html_id}
              location={location}
            />
          </div>
        </>
      )}
    </div>
  );
}
