"use client";
import useDegreeDetailRedesignTraffic from "@/monarch/rules/degreeDetailRedesignTraffic/useDegreeDetailRedesignTraffic";

interface WithMonarchProps {
  Control: React.ReactNode;
  TestComponent: React.ReactNode;
  Loader?: React.ReactNode;
}

const Spinner = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
    }}
  >
    <div
      style={{
        border: "4px solid rgba(0, 0, 0, 0.1)",
        borderLeftColor: "#3498db",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        animation: "spin 1s linear infinite",
      }}
    ></div>
    <style jsx>{`
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}</style>
  </div>
);

export const MonarchResolver = ({
  Control,
  TestComponent,
  Loader,
}: WithMonarchProps) => {
  const { isLoading, showRedesign } = useDegreeDetailRedesignTraffic();
  if (isLoading && Loader) {
    return Loader;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return showRedesign ? TestComponent : Control;
};
