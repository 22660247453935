"use client";

import React from "react";
import { usePathname } from "next/navigation";
import Icon from "@/components/icons";
import type { Footnote } from "./types";

interface FootnotesProps {
  footnoteList: Footnote[];
}

export default function Footnotes({ footnoteList }: FootnotesProps) {
  const pathname = usePathname();

  if (footnoteList.length === 0) {
    return null;
  }

  return (
    <aside role="doc-footnote" className="container py-8">
      <ul className="!p-0">
        {footnoteList.map((footnote, index) => {
          let citationHref = `#citation-${footnote.id}`;
          if (footnote.pageSlug && !pathname.includes(footnote.pageSlug)) {
            citationHref = `${footnote.pageSlug}${citationHref}`;
          }
          return (
            <li
              id={`footnote-${footnote.id}`}
              key={footnote.id}
              className="flex items-center gap-2 text-xs scroll-mt-28 [&_a]:text-info-dark hover:[&_a]:underline"
            >
              <span>{`[${index + 1}]`}</span>
              <div className="flex items-center gap-1">
                {Array.isArray(footnote.content) ? (
                  footnote.content.map((part, i) => {
                    if (React.isValidElement(part) && part.type === "a") {
                      const element = part as React.ReactElement<
                        React.AnchorHTMLAttributes<HTMLAnchorElement>
                      >;
                      return React.cloneElement(element, {
                        className: `${element.props.className || ""} inline-flex items-center gap-1`,
                        target: "_blank",
                        rel: "noopener noreferrer",
                        key: i,
                        children: (
                          <>
                            {element.props.children}
                            <span className="icon inline-block w-4 h-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1em"
                                height="1em"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                focusable="false"
                                aria-hidden="true"
                              >
                                <path d="M19 19H5V5h7V3H3v18h18v-9h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
                              </svg>
                            </span>
                          </>
                        ),
                      });
                    }
                    return <span key={i}>{part}</span>;
                  })
                ) : (
                  <span>{footnote.content}</span>
                )}
              </div>
              <a
                href={`#citation-${footnote.id}`}
                className="inline-block text-info-dark hover:text-primary"
                data-testid="citation-link"
              >
                <Icon
                  type="arrow-upward"
                  svgProps={{ height: 12, width: 12 }}
                />
                <span className="sr-only">
                  Return to footnote {index + 1} reference in main content
                </span>
              </a>
            </li>
          );
        })}
      </ul>
    </aside>
  );
}
