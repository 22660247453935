"use client";

import BaseInternalNav from "@/components/InternalNav";
import { NavProps } from "@/components/InternalNav/types";
import useDegreeDetailRedesignTraffic from "@/monarch/rules/degreeDetailRedesignTraffic/useDegreeDetailRedesignTraffic";
import { validateLocation } from "@/utils/functions/validateLocation";

export default function InternalNav(props: NavProps) {
  const { isLoading, stickyCta } = useDegreeDetailRedesignTraffic();
  const isValidLocation = validateLocation(
    props.locationRestrictions?.allowedIn || [],
    props.locationRestrictions?.blockedIn || [],
  );

  if (isLoading) {
    return null;
  }

  return (
    <BaseInternalNav
      {...props}
      cta={{
        label: stickyCta?.text ?? (props.cta?.label as string),
        url: stickyCta?.url ?? (props.cta?.url as string),
        show: stickyCta?.hideButton !== true && isValidLocation,
      }}
    />
  );
}
