import { ReactNode } from "react";

interface BulletedTextListDescriptionProps {
  description: string | ReactNode;
}

export default function BulletedTextListDescription({
  description,
}: BulletedTextListDescriptionProps) {
  return (
    <div className="mt-5 elm-p4 leading-learn-hero-line-height">
      {typeof description === "string" ? <p>{description}</p> : description}
    </div>
  );
}
